import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb';
import { Box } from '@twilio-paste/box';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { Card } from '@twilio-paste/card';
import { Disclosure, DisclosureHeading, DisclosureContent } from '@twilio-paste/disclosure';
import { Grid, Column } from '@twilio-paste/grid';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Text } from '@twilio-paste/text';
import { useUID } from 'react-uid';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/patterns/create/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <Breadcrumb mdxType="Breadcrumb">
  <BreadcrumbItem to="/" mdxType="BreadcrumbItem">Home</BreadcrumbItem>
  <BreadcrumbItem to="/patterns" mdxType="BreadcrumbItem">Patterns</BreadcrumbItem>
      </Breadcrumb>
      <Heading as="h1" variant="heading10" mdxType="Heading">
  {props.pageContext.frontmatter.title}
      </Heading>
      <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
      <Box as="dl" display="flex" mdxType="Box">
  <Text as="dt" color="colorTextWeak" lineHeight="lineHeight30" mdxType="Text">
    <Box width="size10" mdxType="Box">Status</Box>
  </Text>
  <Text as="dd" mdxType="Text">alpha</Text>
      </Box>
      <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">This is an ALPHA pattern, and we need your feedback!</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    This pattern is still going through feedback cycles, so if you have feedback or ideas related to this pattern,
    please create a <Anchor href="https://github.com/twilio-labs/paste/discussions" mdxType="Anchor">GitHub Discussion</Anchor> and let
    us know.
  </CalloutText>
      </Callout>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Ingredients`}</h2>
        <Grid gutter="space30" marginBottom="space80" mdxType="Grid">
  <Column mdxType="Column">
    <Card mdxType="Card">
      <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
        <Anchor href="/components/button" mdxType="Anchor">Button</Anchor>
      </Heading>
    </Card>
  </Column>
  <Column mdxType="Column">
    <Card mdxType="Card">
      <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
        <Anchor href="/components/anchor" mdxType="Anchor">Anchor</Anchor>
      </Heading>
    </Card>
  </Column>
  <Column mdxType="Column">
    <Card mdxType="Card">
      <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
        <Anchor href="/components/menu" mdxType="Anchor">Menu</Anchor>
      </Heading>
    </Card>
  </Column>
  <Column mdxType="Column">
    <Card mdxType="Card">
      <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
        <Anchor href="/components/toast" mdxType="Anchor">Toast</Anchor>
      </Heading>
    </Card>
  </Column>
        </Grid>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`// import all ingredients for the create patterns

import {​
  Button,
  Anchor,
  Menu,
  Toast
} from '@twilio-paste/core
`}</code></pre>
        <h2>{`Usage`}</h2>
        <h3>{`General`}</h3>
        <p>{`The create pattern should be used when a user is creating a new instance of something, such as a service or a contact list. The create action is often the most important action on the page, so it’s important to drive consistency around how we present this action to our users.`}</p>
        <p>{`The create action should:`}</p>
        <ul>
          <li parentName="ul">{`Be initiated by either a Button or an Anchor (also known as a “link”).`}</li>
          <li parentName="ul">{`Use plain, direct language, so that the user knows what is going to happen when they click it. For example, “create new service” instead of just “create”.`}</li>
          <li parentName="ul">{`Start with the word "create" when the user is creating a new object, like a new service.`}</li>
          <li parentName="ul">{`Start with the word "add" when the user is choosing an existing object, like an existing phone number.`}</li>
          <li parentName="ul">{`Be placed near where the object will appear once it has been created, such as at the top of a table if there are pre-existing instances, or in the center of an empty state, if the user has not created any instances of this object yet.`}</li>
        </ul>
        <h3>{`Accessibility`}</h3>
        <p>{`Use an anchor if the create action will take the user to a new page, and in all other cases, use a button. There is more information on how this works in practice in the `}<a parentName="p" {...{
            "href": "#anchor"
          }}>{`anchor`}</a>{` variant of the create pattern.`}</p>
        <h2>{`Variations`}</h2>
        <h3>{`Standard`}</h3>
        <p>{`This is a standard create pattern. The create action is the primary action on the page, and clicking it will trigger an in-page interaction, such as opening a modal where the user will enter details about the new object.`}</p>
        <p>{`The create action is placed at the top right of the area where the object will appear once it has been created. In this example, the entire page is dedicated to displaying the user’s services, so the “create new service” action is placed at the top of the page next to the page header.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.49019607843137%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABzUlEQVQoz4WRzW7TQBDHfSEk7aHieVJ4Li59AXiKqndU0R7ackpcktAUUFQVULjSNPbaqb/W3q/xMLNpUSIksPTTzozH//3POOh1+m+7z/pficteZ3+023012nn+csQx1f7JDvW82PV83OvtX3U7/aPg/Gz8YTqdYzic4WRy68/LcIafJt/wevoDp1ePbMYEvxuNv+Px+S2+O7vBi8Ech4MvPwPr7HtcPwbAucVy6SIRO8r/S2bQHZzU7vWxtEfXwBqzYLXKT4FiKRtX1w0m6QPGIsUoTvB+Gf9hGQl/RnQ+xTH1JEKgiGNw1qC19ibIsvKUpZtGOSDlPM9JvEKOjbGolMa2bT0Ara875zycc09elECvubYt6KjhTpS4SCQqwx87fMgKLMvKU1USS4Kc+B1Z67CgOjkGrQ1fti1oqGGRKiwqjXWtPJuj86icszA7r6oahVjxikAp8+SwOOEdkqCxFuBXouAuVRBnGkqpgfYEi/toC3JJvw9AyhoEidFFVtL+qTQL8ry8YIe8D36KBjEnOOUKu6QfhtUGzrWPIwPWjfa1tQbMA7rhTbrKPmd5MdBah9as4djn1v4F7SukkUNtTEhjMUNjzJgcH/4GSUq5yMJ/XAcAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "table with a primary action button placed at the top right",
              "title": "table with a primary action button placed at the top right",
              "src": "/static/ecbe975f61e40bae5996ec1e132a5cce/1d499/create-standard.png",
              "srcSet": ["/static/ecbe975f61e40bae5996ec1e132a5cce/e7c18/create-standard.png 408w", "/static/ecbe975f61e40bae5996ec1e132a5cce/b4098/create-standard.png 816w", "/static/ecbe975f61e40bae5996ec1e132a5cce/1d499/create-standard.png 1632w", "/static/ecbe975f61e40bae5996ec1e132a5cce/2ef06/create-standard.png 2448w", "/static/ecbe975f61e40bae5996ec1e132a5cce/6c0bd/create-standard.png 3186w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h5" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">Coming soon!</DisclosureContent>
  </Disclosure>
        </Box>
        <h3>{`Multi-option`}</h3>
        <p>{`In some cases, there will be multiple types of an object the user can create. In this case, the create action should trigger a menu which lists the different options. Whenever possible, use a single button with a menu rather than including multiple buttons or anchors.`}</p>
        <p>{`In the case where the user can either create a new object or add an existing object from the same trigger, the trigger for the action should start with "add" rather than "create". For example, when the trigger allows the user to either add an existing phone number or buy a new phone number, the action text should be "add phone number".`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "43.62745098039216%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABmklEQVQoz42Q204UQRCGJ4IjCb7Qru9k4r0v4FuAe6c37HqDiInDjjcECZL4AMAe2Dn27M6hDzX9W90YXWNiqORLVVd1Vf3dQbgzePP0yeCSOQt3hvGz3Rex83x+FCGzHw7j5+Hw697uIArG4+hjPP2BTyfniL5c4fPphffT6TWmZ9//S8x3ougaH46vMGaOT74hoJ6O8GCKoZvZjLTR5OLHUBPo9aSml+9qezmHDIqimvQ9UG9aMoZQiQo9EXpOEvu6aX1sLdjbX/QepTTyvESaCyt1D6m0DoRYT5y8tu3IXUoriWxjeFjvm0W1RlEIFKXwcckL66bhBRbcjzQrkKaZFZzX+q+BktyQ26TFzapB03boOokkyXF7t8DdbIHZ/J5Z+iGuxj0+ni9WdrFMIFmiG3hEZJ1CyRLNspAmFcpobTylWJv7VWqSNDerJPOw4t91Vm342SYvSuJzF1TV5hRbRvjXWDj49d675dvmTnYrFfDGV1lWvi/K6pCfMdJKjhTTyQeUUiP+mhH/l/c+5tyfuq+9dXXuP/gJ4G9rBdZYtYMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "table with a primary action button that opened a menu",
              "title": "table with a primary action button that opened a menu",
              "src": "/static/728129b47ccfc35596972fec4165c5ad/1d499/create-multi-option.png",
              "srcSet": ["/static/728129b47ccfc35596972fec4165c5ad/e7c18/create-multi-option.png 408w", "/static/728129b47ccfc35596972fec4165c5ad/b4098/create-multi-option.png 816w", "/static/728129b47ccfc35596972fec4165c5ad/1d499/create-multi-option.png 1632w", "/static/728129b47ccfc35596972fec4165c5ad/2ef06/create-multi-option.png 2448w", "/static/728129b47ccfc35596972fec4165c5ad/6c0bd/create-multi-option.png 3186w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h5" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">Coming soon!</DisclosureContent>
  </Disclosure>
        </Box>
        <h3>{`Anchor`}</h3>
        <p>{`If clicking the create action will take the user to a new page, use an anchor, but maintain the same placement on the page so that the user can find the action easily. An “add” icon should be used with the anchor to draw the eye and add further clarity.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "52.69607843137255%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABrUlEQVQoz42S3U7bQBCFDUreib5OX4J3QVygqFftNUUtSWilSBWCUKmXvYQqxF7bu17H2V97enYXKNCCOtKn1Yx3js7MOhvt7C3BV7AY775ZjHYAzvEdof4S4z+E/u+j3b397MvZDzr5+I3msyWdfr6g2XQZmZ5egguaz6/o7AVmsyuagk/oOz//ScfHi0lGKQzw6zz3Fa/9r9XK54xFQv01BuBTf4iDrO+J2rbzxlhiZU2MVZGyqqlEXlU8UQvUOAkhqeYCOX/4VtfCBTWtzWF0uN1qb6yl23VB65xFVrc5cd6QlC01jSTZbuLZIA81LpqHXGsdBaFxL6h8D6tSSqJhoPtoIWKto9fCY2DOk0Pn/GPBgQqhiUmHSz10h+iiwPgCToJwqAeBgAPBBHopL8ok6B8Jhss3TNF1saVNp0gpHXaT1rBmGK+NNaznGYqKonJ3bg8zj3fqOqVh0eXCuJtSu7azLgREHB7ItZsOmXd4OAenf4F7Oo3sDrIn+wjFZztyrqf/DTh8l5Ulf49f4mir1MQaHcFoEWPMxFo7we+QavofpPoR7nzA67/9Ddv/BujBeuBSAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "table with a primary action link",
              "title": "table with a primary action link",
              "src": "/static/cd34cd80ae427048c367aa6dfc9e691b/1d499/create-anchor.png",
              "srcSet": ["/static/cd34cd80ae427048c367aa6dfc9e691b/e7c18/create-anchor.png 408w", "/static/cd34cd80ae427048c367aa6dfc9e691b/b4098/create-anchor.png 816w", "/static/cd34cd80ae427048c367aa6dfc9e691b/1d499/create-anchor.png 1632w", "/static/cd34cd80ae427048c367aa6dfc9e691b/2ef06/create-anchor.png 2448w", "/static/cd34cd80ae427048c367aa6dfc9e691b/6c0bd/create-anchor.png 3186w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h5" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">Coming soon!</DisclosureContent>
  </Disclosure>
        </Box>
        <p>{`If you find, through usability testing, that the anchor style does not give the create action enough visual prominence, you can use an anchor styled as a button instead. `}<strong parentName="p">{`Only do this in cases where using the anchor style hinders the usability of the feature`}</strong>{`, as styling an anchor as a button comes with `}<a parentName="p" {...{
            "href": "https://marcysutton.com/links-vs-buttons-in-modern-web-applications"
          }}>{`accessibility trade-offs`}</a>{`.`}</p>
        <p>{`When using an anchor styled as a button, consider adding an arrow icon to offer some affordance that the button will navigate the user to a new page. Also, ensure to add the `}<inlineCode parentName="p">{`as="a"`}</inlineCode>{` prop to the button component so that it is rendered as an anchor semantically, while maintaining button styling.`}</p>
        <p>{`For more information about using buttons and anchors, read the `}<a parentName="p" {...{
            "href": "/patterns/button-vs-anchor"
          }}>{`best practices for button and anchor usage guide`}</a>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "52.69607843137255%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB4klEQVQoz42S22oUQRRFO5OJol80+R2/QPAhH+BfhDyEwScvRMEkajKXYINoZiL45puYODNdfe+anq5b9/ZUdQJxDOKBRVGb05t9TrXX3ehNiTPC3+ps+90Ngs6ta6y+ziZxr9PzH3a3/QcEnWf3N3tfu53ejjcefcPx0ScMB1OcfDjH4HTqOD2ZEOcYDi8wWmM8usDx+ykO3k7w+nCCl2++YPzxO44O/b6HtiRhFkFg4jQxV7OZCcLQYfW7+HwF8+RAmKfvlHn8SsjBD+ez69U1wHlppFQIowRhGDuiOEFE9zhOW5KMtBRpViBNM7Aoxa8gwZwlmLFUWzch5J5LuFoJI5XCfMGwCELHbB7QhzmKgiPPCxR86c6c7lbL8hy8KCgMh5LSGZLHjWFlaopaUAOaBjfFyUQpjX+VMQZJmrkmrc1twwYsEwgLTU01+TY0Xg5G42eUzBpb3RpYNGFD0LcIWNQamluGtvkyrPCTrbAsK1SVQEJ7c2tYhDQudxqtZ40KjMX6Ou2eZ0yDsqwERdRBJvVlJDQvlbZFJpoeSPNlSTej6eE0Jf0L6hPtyHrX+2MfVlzbkdY1/rco4TMvitLn9Evsr6qqr6Rw0GgOKWVfKdWn36HVxB20+j71vKDXf/QbdW8DSQ5w7yoAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "table with primary action link styled as a button",
              "title": "table with primary action link styled as a button",
              "src": "/static/1e2847f8236e78060342e3f6f3b06f26/1d499/create-anchor-as-button.png",
              "srcSet": ["/static/1e2847f8236e78060342e3f6f3b06f26/e7c18/create-anchor-as-button.png 408w", "/static/1e2847f8236e78060342e3f6f3b06f26/b4098/create-anchor-as-button.png 816w", "/static/1e2847f8236e78060342e3f6f3b06f26/1d499/create-anchor-as-button.png 1632w", "/static/1e2847f8236e78060342e3f6f3b06f26/2ef06/create-anchor-as-button.png 2448w", "/static/1e2847f8236e78060342e3f6f3b06f26/6c0bd/create-anchor-as-button.png 3186w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h5" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">Coming soon!</DisclosureContent>
  </Disclosure>
        </Box>
        <h3>{`Sub-section`}</h3>
        <p>{`When a single page includes multiple, separate create actions that are separated by something like tabs, the create action must be placed in the correct hierarchy. Instead of placing the action in the page header, the create action should be placed within the sub-section, such as at the top right of a table within a tab.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "61.519607843137244%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAACEElEQVQoz21S227TQBD1CyGFTwrwY3wCX4F4hCeogApecitNgtqmUaECJARJGzt2fI293psPs2s3LWlXOhqPd/bMmYuz1+q8aD/onBAGe60nh4/bz7Z49PDp1l7DxFDsLoaEcbvVeeUcfPzyaTL5gX5viqOjc/R6p+h1T60/GJyh36/tcDjDkOxo9A2TyQUm4xuMR99xcvwL3e7xT0cq+Rb1EYBWS89T88VC+UGgPN9XV66r/HVg/RX5FHcfZMMxdcIw2dcayHOmhJAoS46cA1xW2GwKcC5sZFVVBHqtNEE1VkNKBSHogw7Fzpw4zvaNw1ippJREksOPMoRxhihOkKQZ8qKg/xt7VxPX0LpqhMESEvkNISlTRArX80ltblUISmBsUUrkBK0VtgxVTXZ+KfBhxvTsyiRQtxVyKllg5QdGrc1uyMqyRMYUUlbdKp3uGnXvpzmev1np118ZXar/S9bUzCQrUHeEVJAfrENQLwwVds/1H1lE2lRNBLs91PjtMbghg+AcRVHCWwVYXLrwg9AqN0NjBozXA6SYpbfWecGNAEv4zkyZgoWkFIuA6WVYanI151yHYazXYaRpIOSLO6DN0HGcqrpN+sxJkuygmZCV72dUdlGviEmUJBuk6cb698Gui6jXkNbowomi9CU9CGkP/1CGORdqTlLnNJA5JZnToCzMt1R30cT9JeuS2s//AF7lXKEf4VdoAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "table inside of a tab with a primary action button",
              "title": "table inside of a tab with a primary action button",
              "src": "/static/5f74fa1c26316f00a0eaa7001e1c141e/1d499/create-sub-section.png",
              "srcSet": ["/static/5f74fa1c26316f00a0eaa7001e1c141e/e7c18/create-sub-section.png 408w", "/static/5f74fa1c26316f00a0eaa7001e1c141e/b4098/create-sub-section.png 816w", "/static/5f74fa1c26316f00a0eaa7001e1c141e/1d499/create-sub-section.png 1632w", "/static/5f74fa1c26316f00a0eaa7001e1c141e/2ef06/create-sub-section.png 2448w", "/static/5f74fa1c26316f00a0eaa7001e1c141e/6c0bd/create-sub-section.png 3186w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h5" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">Coming soon!</DisclosureContent>
  </Disclosure>
        </Box>
        <h3>{`Card`}</h3>
        <p>{`When the data being displayed is more suited to a card layout rather than a table layout, the create action will live inside of an empty card at the start of the list of objects.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.32352941176471%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABjklEQVQoz42RyU4CQRCG5yKiPhLqA3n3ETz5DJ48eRUOGE8uqDCJS0gQEOeAKIMOs8DQs3RVl9U9ogcTYyVfKlVT/fdfPdZaqbJfXqncMZdrpc2rjfL21frqlkHX3P8PDaZVLlUOrZP69alt9+ni/J6aNx2TG422Qde23SW79Tet5iPd3Q7o7Oz2yZIgj6mIXCmE4egVvOkUPN8H7v0X+aXxYPl+VEMkimMBHASAJGWRNVmWUxDOKAiWRCRESlEUm5rPUxjOUEoz27bCcF7T0kmSArKyH4Q8GBICmCu1uOMMqdMdUK/v0GjkUprmNHx5o8feM3WZsfthBCXAb0F34tGr+2FymmWEqCheCHaVGLf6As1ikTCi6OWsVlxuBKt65TRJ80wi7lUD3Dn0cPdoiiLTXxRO3j0cuxNcCKEPGrxpgKO3Mc7jGJVSUAjKByuK5ifL1XSkPC6gYBlLR0r99PT7auc6f88B9Cx+5INoFk/444BXdtiQwwYM/JMM7IBrcnj9Xz2duX5m4Zcsz+uf4Z1xYIjvQrIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "list of three cards, the first of which is an empty card with a primary action button",
              "title": "list of three cards, the first of which is an empty card with a primary action button",
              "src": "/static/dd4e4458147774d122b5611ccb66fc0a/1d499/create-card.png",
              "srcSet": ["/static/dd4e4458147774d122b5611ccb66fc0a/e7c18/create-card.png 408w", "/static/dd4e4458147774d122b5611ccb66fc0a/b4098/create-card.png 816w", "/static/dd4e4458147774d122b5611ccb66fc0a/1d499/create-card.png 1632w", "/static/dd4e4458147774d122b5611ccb66fc0a/2ef06/create-card.png 2448w", "/static/dd4e4458147774d122b5611ccb66fc0a/6c0bd/create-card.png 3186w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h5" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">Coming soon!</DisclosureContent>
  </Disclosure>
        </Box>
        <h3>{`Empty state`}</h3>
        <p>{`If the user has not yet created any instances of this object, they should see an empty state with a create action within it. Follow the Empty State pattern (coming soon!) in this case.`}</p>
        <h2>{`Creation flow`}</h2>
        <p>{`Bringing consistency to what happens after the user triggers the Create action is also a critical part of this pattern.`}</p>
        <Callout variant="info" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">This pattern is coming soon!</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    We've not yet patternized this part of Create. If you need this for your current or upcoming work, please consider
    partnering with us to <Anchor href="/patterns/contribute" mdxType="Anchor">contribute it</Anchor>.
  </CalloutText>
        </Callout>
        <h2>{`Post-creation`}</h2>
        <p>{`After the user has created a new object, navigate them either back to the index page, where they can see a list of all objects, or to the individual object detail page (whichever makes most sense for the given user flow), and show a success `}<a parentName="p" {...{
            "href": "../components/toast"
          }}>{`Toast`}</a>{` informing them that the object has successfully been created.`}</p>
        <p>{`If the create action fails, keep the user where they were and display an error `}<a parentName="p" {...{
            "href": "../components/toast"
          }}>{`Toast`}</a>{` that explains what went wrong and how to try again.`}</p>
        <p>{`For more information, check out our `}<a parentName="p" {...{
            "href": "/patterns/notifications"
          }}>{`Notifications and Feedback patterns`}</a>{`.`}</p>
        <h2>{`Starter kits`}</h2>
        <h3>{`CodeSandbox`}</h3>
        <p>{`Coming soon`}</p>
        <h3>{`Figma`}</h3>
        <p>{`Coming soon`}</p>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      